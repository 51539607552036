//@ts-nocheck
import React from 'react';
import {ToggleButton, Tab, Form} from 'react-bootstrap';
import * as _ from 'lodash';
import * as Table from 'reactabular-table';
import * as edit from 'react-edit';
import * as Api from 'utils/api/api.js';
import * as resizable from 'reactabular-resizable';
import {v4 as uuid} from 'uuid';
import 'reactabular-resizable/style.css'
import * as Sticky from 'reactabular-sticky';
import moment from 'moment';
import {EDITITNG_TYPE, KEYS} from 'utils/constants';
import Confirm from '../../../popup/ConfirmActionPopup.component';
import AccountingGenericInput from '../../../input/AccountingGenericInput.component';
import {formatAmountValue} from 'utils/valueFormatter.function';
import {handleError} from 'utils/errorHandle.function';
import {getDateFormatFromLanguage} from "utils/dateFormatter";

const SELECTED_ROW_CLASS = " selected-row ";
const SELECTED_CELL_CLASS = " selected-cell ";

const ACCOUNT_NAME = "ACCOUNT_NAME";
const COST_CENTER_NAME = "COST_CENTER_NAME";
const APPROVED = "APPROVED";
const REJECTED = "REJECTED";

const MAX_COLUMNS_FIT_SMALL_SCREEN = 10;
const MAX_COLUMNS_FIT_BIG_SCREEN = 15;

const WIDTH_WHEN_SCROLL = 130;
const FIXED_GRID_WIDTHS = {
    LA_ACTIONS: 72,
    DATE: 140,
    DECIMAL: 102,
    LA_STATUS: 72,
    ACCOUNT_NAME: 170,
    CURRENCY: 56
};
const MARGINS_WIDTH = 30;
let isWideScreen = document.body.clientWidth > 1300;


const resizableHelper = resizable.helper({
    globalId: uuid(),
    getId: ({property}) => property
});

/*
 * reactabular table component
 * for more information and full api : https://reactabular.js.org/#/
 * */
class AccountingGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [],
            onlyMyLines: false,
            rows: [],
            allRows: [],
            allColumns: [],
            userData: {},
            selectedRow: undefined,
            selectedCell: undefined,
            policy: {},
            visible: false,
            inEditMode: false,
            hasOwner: true,
            gridWidth: props.width ? props.width - MARGINS_WIDTH : document.body.clientWidth - MARGINS_WIDTH
        };
        //region functions
        this.tableHeader = null;
        this.tableBody = null;
        this.deleteActionOngoing = false;
        this.decBody = null;
        this.approveLine = this.approveLine.bind(this);
        this.rejectLine = this.rejectLine.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.showInfo = this.showInfo.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.onClickSelectRow = this.onClickSelectRow.bind(this);
        this.onRow = this.onRow.bind(this);
        this.getGridRows = this.getGridRows.bind(this);
        this.toggleGrid = this.toggleGrid.bind(this);
        this.requestData = this.requestData.bind(this);
        this.close = this.close.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.moveSelectionRight = this.moveSelectionRight.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.moveSelectionLeft = this.moveSelectionLeft.bind(this);
        this.moveSelectionUp = this.moveSelectionUp.bind(this);
        this.moveSelectionDown = this.moveSelectionDown.bind(this);
        this.moveToNextEditableCell = this.moveToNextEditableCell.bind(this);
        this.moveToPreviousEditableCell = this.moveToPreviousEditableCell.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.onFilterLinesChanged = this.onFilterLinesChanged.bind(this);
        this.getFilteredLines = this.getFilteredLines.bind(this);
        this.hideColumn = this.hideColumn.bind(this);
        this.selectLastRow = this.selectLastRow.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.checkUpdateCellResponse = this.checkUpdateCellResponse.bind(this);
        this.moveSlection = this.moveSlection.bind(this);
        //endregion

        this.requests = [];
    }


    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.requestData(this.props.taskId);
    }

    handleScroll(event) {
        this.decBody.scrollTop = event.offsetY;
    }

    //this is needed to reload data at navigate between tasks with ctrl+alt+n
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.taskId !== this.props.taskId || prevProps.readOnly !== this.props.readOnly) {
            this.setState({
                rows: [],
                selectedRow: undefined,
                selectedCell: undefined,
                allRows: [],
                allColumns: [],
                columns: []
            }, () => {
                this.props.onUpdateRows({rows: 0, totalRows: 0});
                this.requestData(this.props.taskId);
            });
        }

        if (prevProps.width !== this.props.width) {
            this.updateDimensions();
        }
    }

    componentWillUnmount() {
        this.requests.forEach(p => p.cancel());
        window.removeEventListener("resize", this.updateDimensions);
        //this creates an issue in IE 11 because of .remove()
        // resizableHelper.cleanup();
    }

    updateDimensions() {
        this.setState({
            gridWidth: this.props.width ? this.props.width - MARGINS_WIDTH : document.body.clientWidth - MARGINS_WIDTH
        });
    }

    requestData(taskId) {
        let numFixedColumns = 0;
        let showDecisionColumn = this.props.readOnly !== true;
        let availableWidth = this.state.gridWidth ? this.state.gridWidth - MARGINS_WIDTH : this.tableBody.getBoundingClientRect().width;

        this.requests.push(Api._getAccountingGridHeader(this.props.taskId).then(function (response) {
                // Handle visibility of columns related to line approval
                for (const col of response.columns) {
                    switch (col.clientId) {
                        case "XappSubstitutedInfo":
                        case "XappIsSubstitute":
                        case "XappIsOwner":
                            col.hidden = true;
                            break;
                        case "XappLineApprover":
                        case "XappStatus":
                            col.hidden = false;
                            break;
                        // Show the decision column only when the grid is not read only
                        case "XappDecision":
                            col.hidden = !showDecisionColumn;
                            break;
                        default:
                    }
                }
                /*filter out the hidden columns, this is done for the reactabular, the component does not compute the widths of the
                 columns properly if there are hidden columns
                 */
                let visibleColumns = _.filter(response.columns, function (col, index) {
                    if (!col.hidden) {

                        switch (col.dataTypeName) {
                            case "LITERAL": {
                                //TO DO: change when backend is changed in production
                                if (!col.editable) {
                                    numFixedColumns += 1;
                                    col.width = FIXED_GRID_WIDTHS.CURRENCY;
                                    availableWidth -= FIXED_GRID_WIDTHS.CURRENCY;
                                    col.columnindex = index;
                                    return col;
                                } else {
                                    col.columnindex = index;
                                    return col;
                                }
                            }
                            case "DATE":
                            case "DECIMAL":
                            case "LA_ACTIONS":
                            case "ACCOUNT_NAME":
                            case "LA_STATUS": {
                                numFixedColumns += 1;
                                col.width = FIXED_GRID_WIDTHS[col.dataTypeName];
                                availableWidth -= FIXED_GRID_WIDTHS[col.dataTypeName];
                                col.columnindex = index;
                                return col;
                            }
                            default: {
                                col.columnindex = index;
                                return col;
                            }
                        }
                    }
                });

                let header = this.getHeaders(visibleColumns, availableWidth, numFixedColumns);
                this.getGridRows(true, false, false, response.columns);
                // Patch the column definition with class names.
                this.setState({
                    columns: resizableHelper.initialize(header),
                    allColumns: response.columns,
                    policy: response.policy
                });


            }.bind(this),
            function (error) {
                handleError(error);
            }
        ));

    }

    getGridRows(update = true, moveNext = false, movePrevious = false, columns = this.state.allColumns, afterDone = undefined) {
        this.requests.push(Api._getAccountingGridRows(this.props.taskId).then(function (response) {
                //to be replaced with clientId XAPPOwner
                let actionsEnabledIndex = _.findIndex(this.state.allColumns, {clientId: "XappIsOwner"});
                this.deleteActionOngoing = false;
                this.setState(function () {
                    let stateValue = {
                        allRows: response,
                        rows: response.rows,
                        userData: response.userdata,
                        hasOwner: actionsEnabledIndex !== -1,
                        inEditMode: false,
                        addingRemovingRow: false
                    }
                    return stateValue;
                }, () => {
                    this.props.onUpdateRows({rows: response.rows.length, totalRows: this.getTotalRows()});
                    this.getFilteredLines(response, moveNext, update);
                });

                // if (movePrevious) {
                //     this.moveToPreviousEditableCell();
                // } else if (moveNext) {
                //     this.moveToNextEditableCell();
                // }
                if (afterDone) {
                    afterDone();
                }
            }.bind(this), function (error) {
                handleError(error);
            }
        ));
    }

    getFilteredLines(response, moveNext = false, update) {
        if (this.state.allColumns.length > 0) {
            let actionsIndex = _.findIndex(this.state.allColumns, {dataTypeName: "LA_ACTIONS"});
            //to be replaced with clientId XAPPOwner
            let actionsEnabledIndex = _.findIndex(this.state.allColumns, {clientId: "XappIsOwner"});

            let rows = [];
            response.rows?.forEach((row, index) => {
                if (!row.hidden) {
                    if ((this.state.hasOwner && row.cell[actionsEnabledIndex] === "true") || !this.state.onlyMyLines) {
                        let rowObject = {};
                        let keepEdit = false;
                        if (moveNext || !update) {
                            keepEdit = (this.state.selectedRow === index) ? this.state.selectedCell : undefined;
                        }
                        rowObject.id = row.id;
                        rowObject.canDelete = row.canDelete;
                        rowObject.hidden = row.hidden;
                        rowObject.locked = row.locked;
                        rowObject.actionsEnabled = row.cell[actionsEnabledIndex] === "true";
                        rowObject.action = row.cell[actionsIndex];
                        rowObject.selected = false;
                        rowObject.rowIndex = index;
                        rowObject.bckRowIndex = row.rowIndex;
                        rowObject.editing = keepEdit;
                        rowObject.version = row.version;
                        let count = 0;
                        row.cell.forEach((cell, index) => {
                            let value = cell;

                            //do not add the cells for the hidden columns
                            if (!this.state.allColumns[index].hidden) {
                                if (this.state.columns[count].editingType === ACCOUNT_NAME) {
                                    response.userdata.accounts.forEach(account => {
                                        if (account.number === cell)
                                            value = account.number + " - " + account.name
                                    });
                                } else if (this.state.columns[count].editingType === COST_CENTER_NAME) {
                                    response.userdata.costCenters.forEach(account => {
                                        if (account.dimension.toString() === this.state.allColumns[index].properties.map.dimension && account.number === cell)
                                            value = account.number + " - " + account.name
                                    });
                                }

                                rowObject[count] = value;
                                count += 1;
                            }
                        });
                        rows.push(rowObject);
                    }
                }
            });

            this.setState({
                rows: rows
            }, () => {
                this.props.onUpdateRows({rows: rows.length, totalRows: this.getTotalRows()});
                if (moveNext) {
                    this.moveToNextEditableCell();
                }
            });
        }
    }

    approveLine(rowId, rowVersion) {
        this.requests.push(Api._accoutingLineAction(this.props.taskId, rowId, 1, rowVersion).then(function (response) {
                    this.getGridRows(false);
                }.bind(this),
                function (error) {
                    handleError(error);
                })
        );
    }

    selectLastRow() {
        if (this.state.rows.length > 0) {
            let newSelectedRow = this.state.rows.length - 1;
            let newSelectedColumn = -1;
            this.setState({
                selectedRow: newSelectedRow,
                selectedCell: newSelectedColumn
            });
            this.changeSelectedRow(this.state.rows.length - 1, newSelectedColumn);
            this.moveToNextEditableCell();
            this.enterEditMode();
            this.tableBody.scrollTop = this.tableBody.scrollHeight;
        }
    }

    rejectLine(rowId, rowVersion) {
        this.requests.push(Api._accoutingLineAction(this.props.taskId, rowId, -1, rowVersion).then(function (response) {
                this.getGridRows(false);
            }.bind(this),
            function (error) {
                handleError(error);
            }));
    }

    showInfo() {
        // alert("not implemented yet");
    }

    lockButtons(promiseSupplier) {
        if (this.state.addingRemovingRow === true) {
            return;
        }
        this.setState({
            addingRemovingRow: true
        });
        this.requests.push(promiseSupplier.call(this)
            .finally(function () {
                this.setState({
                    addingRemovingRow: false
                });
            }.bind(this)));
    }

    addRow() {
        this.lockButtons(function () {
            return Api.addNewAccoutingRow(this.props.taskId)
                .then(function (response) {
                        this.setState({
                            selectedRow: undefined,
                            selectedCell: undefined
                        });
                        this.getGridRows(false, false, false, this.state.allColumns, this.selectLastRow);
                    }.bind(this),
                    function (error) {
                        handleError(error);
                    })
        });
    }

    removeRow() {
        let {rows} = this.state;
        this.lockButtons(function () {
            return Api._deleteAccoutingRow(this.props.taskId, rows[this.state.selectedRow].bckRowIndex, rows[this.state.selectedRow].version)
                .then(function (response) {
                        this.close();
                        this.setState({
                            selectedRow: undefined,
                            selectedCell: undefined
                        });
                        this.getGridRows(false);
                    }.bind(this),
                    function (error) {
                        handleError(error);
                    })
        });
    }

    refreshGrid(event) {
        this.setState({
            selectedRow: undefined,
            selectedCell: undefined
        });
        this.getGridRows(false);
    }

    toggleGrid(event) {
        this.props.toggle(event);
    }

    showPopup(event) {
        this.deleteActionOngoing = true;
        this.setState({
            visible: true
        });
    }

    //possible improvement: Simona - check with Alban
    checkUpdateCellResponse(response) {
        let updatedRows = response.results;
        let rowsAreSame = true;
        if (updatedRows.length > 0) {
            let rowId = updatedRows[0].rowid;
            let allRows = _.cloneDeep(this.state.allRows.rows);
            let currentRowIndex = _.findIndex(this.state.allRows.rows, {id: rowId + ""});
            //compare current row values to the backed values
            if (currentRowIndex !== -1) {
                updatedRows.forEach(cellValue => {
                    if (cellValue.data !== allRows[currentRowIndex].cell[cellValue.colname])
                        rowsAreSame = false;

                });
            } else {
                rowsAreSame = false;
            }

        }
        return rowsAreSame;
    }

    moveSlection(rows, value) {
        this.setState({
            rows: rows,
            inEditMode: false
        });
        if (value.hasToMoveNext) {
            this.moveToNextEditableCell();
        } else if (value.hasToMovePrevious) {
            this.moveToPreviousEditableCell();
        } else if (this.tableBody) {
            this.tableBody.focus();
        }
    }

    getHeaders(columns, availableWidth, numFixedColumns) {
        const resizableFormatter = resizable.column({
            getWidth: column => column.header.props.style.width,
            onDrag: (width, {column}) => {
                //do not resize under this value
                if (width > 50) {
                    resizableHelper.update({
                        column,
                        width
                    });
                }
            }
        });


        const editable = edit.edit({
            isEditing: ({columnIndex, rowData, row}) => {
                //do not enable edit if another cell is being edited
                if (this.state.rows.some(row => row.editing && columnIndex !== rowData.editing)) {
                    return false;
                }
                return columnIndex === rowData.editing;
            },
            onActivate: ({columnIndex, rowIndex, rowData}) => {

            },
            onValue: ({value, rowData, column, property}) => {
                if (this.deleteActionOngoing) return;
                if (value.val === null) {
                    const rows = _.cloneDeep(this.state.rows);
                    if (rows[rowData.rowIndex])
                        rows[rowData.rowIndex].editing = false;
                    this.moveSlection(rows, value);
                    return;
                }
                if (this.state.inEditMode) return;

                const index = _.findIndex(this.state.rows, {bckRowIndex: rowData.bckRowIndex});
                const rows = _.cloneDeep(this.state.rows);

                // rows[index].editing = false;
                let newValue = value.val;
                let rowValue = rowData[column.property];

                if ((rowValue || newValue) && rowValue !== newValue) {
                    rows[index][column.property] = newValue;
                    let newRows = _.cloneDeep(this.state.rows);
                    let oldRows = _.cloneDeep(this.state.rows);
                    newRows[index][column.property] = newValue;
                    this.setState({
                        inEditMode: true,
                        rows: newRows
                    });

                    Api._updateAccoutingCell(this.props.taskId, this.state.rows[index].bckRowIndex, column.index, newValue, rowData.version)
                        .then(function (response) {
                            let toUpdate = value.switchToReadOnly !== undefined ? value.switchToReadOnly : true;
                            this.getGridRows(toUpdate, value.hasToMoveNext, value.hasToMovePrevious);
                        }.bind(this), function (error) {
                            this.setState({
                                inEditMode: false,
                                rows: oldRows
                            });
                            handleError(error);
                        }.bind(this));
                } else {
                    this.moveSlection(rows, value);
                }
            }
        });

        let editCellModel = function (value, extra, index) {
            if (extra.column.editable && !extra.rowData.locked) {
                extra.key = this.props.taskId;
                extra.tabIndex = index;
                extra.translate = this.props.translate;
                extra.language = this.props.language;
                extra.move = this.moveToNextEditableCell.bind(null);
                extra.lineWarning = this.props.lineChangeWarning;
                extra.dataType = extra.column.editingType;

                switch (extra.column.editingType) {
                    case  EDITITNG_TYPE.ACCOUNT: {
                        extra.type = EDITITNG_TYPE.ACCOUNT;
                        return editable(AccountingGenericInput())(extra.rowData[extra.columnIndex], extra, {className: extra.rowData.edited && 'edited'});
                    }
                    case  EDITITNG_TYPE.BOOLEAN: {
                        return editable(edit.boolean())(extra.rowData[extra.columnIndex], extra, {className: extra.rowData.edited && 'edited'});
                    }
                    case  EDITITNG_TYPE.COST_CENTER: {
                        extra.type = EDITITNG_TYPE.COST_CENTER;
                        return editable(AccountingGenericInput())(extra.rowData[extra.columnIndex], extra, {className: extra.rowData.edited && 'edited'});
                    }
                    case EDITITNG_TYPE.DATE: {
                        let dateValueFormatter = getDateFormatFromLanguage(extra.language);
                        let date = !extra.rowData[extra.columnIndex] ? undefined : moment(extra.rowData[extra.columnIndex], dateValueFormatter);

                        extra.type = EDITITNG_TYPE.DATE;

                        return editable(AccountingGenericInput())(date, extra, {className: extra.rowData.edited && 'edited'});
                    }
                    case EDITITNG_TYPE.DECIMAL: {
                        extra.type = EDITITNG_TYPE.DECIMAL;
                        extra.step = "0.01";
                        extra.type = "number";
                        return editable(AccountingGenericInput())(extra.rowData[extra.columnIndex], extra, {className: extra.rowData.edited && 'edited'});

                    }
                    case EDITITNG_TYPE.INTEGER: {
                        extra.step = "1";
                        extra.type = "number";
                        return editable(AccountingGenericInput())(extra.rowData[extra.columnIndex], extra, {className: extra.rowData.edited && 'edited'});
                    }
                    default: {
                        extra.type = "text";
                        return editable(AccountingGenericInput())(extra.rowData[extra.columnIndex], extra, {className: extra.rowData.edited && 'edited'});
                    }
                }
            }
        }.bind(this);

        let cellFormatter = function (rowData, column, columnIndex) {

            let rowLocked = rowData.locked ? " locked-cell " : "";

            switch (column.editingType) {
                case EDITITNG_TYPE.DECISIONS: {
                    let editableClass = rowData.actionsEnabled ? "" : "d-none";
                    let approveClass = rowData.action === APPROVED ? APPROVED.toLowerCase() : "";
                    let rejectClass = rowData.action === REJECTED ? REJECTED.toLowerCase() : "";
                    if (this.props.readOnly)
                        editableClass += " disabled";
                    return (
                        <span tabIndex={columnIndex} className={rowLocked + "decision"}>
                          <span
                              className={"appicon vismaicon-approve float-left mx-2 " + approveClass + " " + editableClass}
                              onClick={this.approveLine.bind(this, rowData.rowIndex, rowData.version)}/>

                            <span className={"appicon float-left vismaicon-reject " + rejectClass + " " + editableClass}
                                  onClick={this.rejectLine.bind(this, rowData.rowIndex, rowData.version)}/>
                        </span>
                    )
                }
                case EDITITNG_TYPE.INFO: {
                    return (
                        <span tabIndex={columnIndex} className={rowLocked + "col-md-12 "}
                              style={{height: "20px", width: column.width - 6 + "px"}}>
                            {rowData[columnIndex]}
                            </span>
                    )
                }
                case EDITITNG_TYPE.DECIMAL: {
                    let cellClass = "";
                    cellClass += rowLocked;
                    return (<div className={cellClass}
                                 style={{height: "20px", width: column.width + "px"}}>
                        <span tabIndex={columnIndex}
                              className={"col-md-12 text-end " + column.editingType.toLowerCase()}>
                           {formatAmountValue(rowData[columnIndex])}
                            </span>
                    </div>)
                }
                case EDITITNG_TYPE.INTEGER: {
                    let cellClass = "";
                    cellClass += rowLocked;
                    return (<div className={cellClass}
                                 style={{height: "20px", width: column.width + "px"}}>
                        <span tabIndex={columnIndex}
                              className={"col-md-12 text-end " + column.editingType.toLowerCase()}>
                           {rowData[columnIndex]}
                            </span>
                    </div>)
                }
                case EDITITNG_TYPE.STATUS: {
                    let status = rowData.actionsEnabled && rowData[columnIndex] === "NOT_EVALUATED" ? "DECIDE" : rowData[columnIndex];
                    let colour = rowData.actionsEnabled && rowData[columnIndex] === "NOT_EVALUATED" ? "#0974b3" : "lightgrey";
                    return (
                        <span tabIndex={columnIndex} className={rowLocked + "col-md-12 px-0"}
                              style={rowData[columnIndex] === "NOT_EVALUATED" ? {color: colour} : {}}>{rowData[columnIndex] ? this.props.translate("accoutingGrid." + status) : ""}</span>)
                }

                default: {
                    let cellClass = (this.state.selectedCell === (columnIndex + 1)) ? "" : "col-md-12 px-0";
                    cellClass += rowLocked;
                    return (<div
                        className={cellClass}>
                        <span tabIndex={columnIndex}
                              style={{height: "30px"}}
                              title={rowData[columnIndex]}
                              className={"col-md-12 text-start " + column.editingType.toLowerCase()}>{rowData[columnIndex]}</span>
                    </div>)
                }
            }
        }.bind(this);

        let getHeaderLabel = function (column) {
            switch (column.dataTypeName) {
                case EDITITNG_TYPE.INFO:
                case  EDITITNG_TYPE.STATUS:
                case EDITITNG_TYPE.DECISIONS:
                    return this.props.translate("accoutingGrid." + column.caption);
                default:
                    return column.caption;
            }
        }.bind(this);

        let normalWidth = availableWidth / (columns.length - numFixedColumns) - 1;

        return columns.map((column, index) => {
                let currentWidth = column.width;
                let columnsToFit = isWideScreen ? MAX_COLUMNS_FIT_BIG_SCREEN : MAX_COLUMNS_FIT_SMALL_SCREEN;

                if (!currentWidth) {
                    if (columns.length > columnsToFit) {
                        currentWidth = WIDTH_WHEN_SCROLL;
                    } else {
                        currentWidth = normalWidth;
                    }
                }
                let headerLabel = getHeaderLabel(column);
                let model = {
                    property: index,
                    width: currentWidth,
                    header: {
                        label: headerLabel,
                        props: {
                            title: headerLabel,
                            width: currentWidth
                        },
                        formatters: [
                            resizableFormatter
                        ]
                    },
                    visible: true,
                    index: column.columnindex,
                    editingType: column.dataTypeName,
                    rowSelected: this.state.selectedRow === column.columnindex
                };
                //add editing mode only if the task is not from My History
                if (!this.props.readOnly) {
                    model.editable = column.editable
                }


                model.cell = {
                    transforms: [
                        (value, extra) => {
                            return editCellModel(value, extra, index)
                        }
                    ],
                    formatters: [
                        (value, {rowData, column, columnIndex}) => cellFormatter(rowData, column, columnIndex)
                    ],
                    width: currentWidth
                }

                if (column.dataTypeName === "LA_ACTIONS") {
                    model.props = Object.assign({
                        className: "decisions" + (column.editable ? " editable-cell" : ""),
                        width: currentWidth
                    }, model.props);
                } else {
                    model.props = Object.assign({
                        className: column.dataTypeName + (column.editable ? " editable-cell" : ""),
                        width: currentWidth
                    }, model.props);
                }
                return model;
            }
        )
    }

    onRow(row, {rowIndex}) {
        return {
            onClick: (event) => this.onClickSelectRow(rowIndex, event)
        };
    }

    close() {
        this.setState({visible: false});
    }

    //region Grid Navigation(keyboard+mouse)

    handleKeyDown(event) {
        event.stopPropagation();
        switch (event.keyCode) {
            case KEYS.UP_ARROW:
                event.preventDefault();
                this.moveSelectionUp(event);
                break;
            case KEYS.DOWN_ARROW:
                event.preventDefault();
                this.moveSelectionDown(event);
                break;
            case KEYS.ARROW_RIGHT:
                event.preventDefault();
                this.moveSelectionRight(event);
                break;
            case KEYS.ARROW_LEFT:
                event.preventDefault();
                this.moveSelectionLeft(event);
                break;
            case KEYS.TAB:
                event.preventDefault();
                if (event.shiftKey) {
                    this.moveToPreviousEditableCell();
                } else {
                    this.moveToNextEditableCell();
                }
                break;
            case KEYS.ENTER:
                event.preventDefault();
                this.enterEditMode();
                break;
            default:
                break;
        }
    }

    // @keydown(38)
    moveSelectionUp(event) {
        if (!this.state.inEditMode && this.state.selectedRow !== undefined && this.state.selectedRow !== 0) {
            let sRow = this.state.selectedRow - 1;
            const rowHeight = this.tableBody.children[sRow].offsetHeight;
            let nextRow = this.tableBody.children[sRow].offsetTop;
            if (nextRow < this.tableBody.scrollTop)
                this.tableBody.scrollTop = this.tableBody.scrollTop - rowHeight;
            this.setState({
                selectedRow: sRow
            });
            this.changeSelectedRow(sRow, this.state.selectedCell);
        }
    }

    //@keydown(40)
    moveSelectionDown(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!this.state.inEditMode && (this.state.selectedRow !== undefined && this.state.selectedRow !== this.state.rows.length - 1)) {
            let sRow = this.state.selectedRow + 1;
            //this.tableBody.scrollTop = 0;
            const rowHeight = this.tableBody.children[sRow].offsetHeight;
            let currentPosition = this.tableBody.children[sRow].offsetTop;
            if (currentPosition >= this.tableBody.clientHeight - rowHeight) {
                this.tableBody.scrollTop = currentPosition + rowHeight;
            }
            this.setState({
                selectedRow: sRow
            });
            this.changeSelectedRow(sRow, this.state.selectedCell);
        }
    }

    //@keydown(39)
    moveSelectionRight(event) {
        event.preventDefault();

        if (!this.state.inEditMode && (this.state.selectedRow !== undefined && this.state.selectedCell !== this.state.columns.length - 1)) {
            let sCell = this.state.selectedCell + 1;
            let currentPosition = this.tableBody.children[this.state.selectedRow].children[sCell].offsetLeft;
            const nextWidth = this.tableBody.children[this.state.selectedRow].children[sCell].offsetWidth;

            if (currentPosition >= window.innerWidth - nextWidth) {
                this.tableBody.scrollLeft = currentPosition + nextWidth;
            }

            this.setState({
                selectedCell: sCell
            });
            this.changeSelectedCell(this.state.selectedRow, sCell);
        }
    }

    //@keydown(37)
    moveSelectionLeft(event) {
        // console.log("moving left "+ this.state.selectedCell +" "+);
        if (!this.state.inEditMode && this.state.selectedRow !== undefined && this.state.selectedCell !== 0) {
            let sCell = this.state.selectedCell - 1;
            let nextPosition = this.tableBody.children[this.state.selectedRow].children[sCell].offsetLeft;
            const nextWidth = this.tableBody.children[this.state.selectedRow].children[sCell].offsetWidth;

            if (nextPosition <= this.tableBody.scrollLeft)
                this.tableBody.scrollLeft = this.tableBody.scrollLeft - nextWidth;

            this.setState({
                selectedCell: sCell
            });
            this.changeSelectedCell(this.state.selectedRow, sCell);
        }
    }

    //@keydown('enter')
    enterEditMode() {
        if (!this.state.inEditMode && this.state.selectedCell !== undefined) {
            if (this.state.columns[this.state.selectedCell].editable) {
                let rows = _.cloneDeep(this.state.rows);
                rows.forEach((row, index) => {
                    if (index === this.state.selectedRow) {
                        // this.setState({
                        //     inEditMode: true
                        // });
                    }
                    row.editing = index === this.state.selectedRow ? this.state.selectedCell : false;
                });
                this.setState({rows});
            }
        }
    }

    //@keydown(9)
    moveToNextEditableCell() {
        if (!this.state.inEditMode && this.state.selectedCell !== undefined) {
            let rows = _.cloneDeep(this.state.rows);
            let nextColumn;
            rows.forEach((row, index) => {
                let willSelect = false;

                if (index === this.state.selectedRow && this.state.selectedCell !== undefined) {
                    let editingColumn = this.state.selectedCell;// row.editing;
                    nextColumn = editingColumn + 1;

                    while (nextColumn < this.state.columns.length && !this.state.columns[nextColumn].editable) {
                        nextColumn += 1;
                    }
                    this.setState({
                        //inEditMode: true,
                        selectedCell: nextColumn
                    });
                    willSelect = nextColumn;
                }
                return row.editing = willSelect;
            });
            this.setState({rows: rows});
            this.changeSelectedRow(this.state.selectedRow, nextColumn);
        }
    }

    moveToPreviousEditableCell() {
        let rows = _.cloneDeep(this.state.rows);
        let nextColumn;
        rows.forEach((row, index) => {
            let willSelect = false;

            if (index === this.state.selectedRow && this.state.selectedCell !== undefined) {
                let editingColumn = this.state.selectedCell;// row.editing;
                nextColumn = editingColumn - 1;

                while (nextColumn >= 0 && !this.state.columns[nextColumn].editable) {
                    nextColumn -= 1;
                }
                this.setState({
                    inEditMode: true,
                    selectedCell: nextColumn
                });
                willSelect = nextColumn;
            }
            return row.editing = willSelect;
        });
        this.setState({rows: rows});
        //console.log("will edit " + nextColumn);
        this.changeSelectedRow(this.state.selectedRow, nextColumn);
    }

    changeSelectedRow(rowindex, columnindex) {
        let rows = this.tableBody.children;

        _.times(rows.length, (index) => {
            if (index === rowindex) {
                rows[index].className = SELECTED_ROW_CLASS;
                this.changeSelectedCell(index, columnindex);
            } else {
                //make sure no cell remains selected for an unselected row
                rows[index].className = "";
                this.changeSelectedCell(index, -1);
            }
        });
    }

    changeSelectedCell(rowindex, cellIndex) {
        let columns = this.tableBody.children[rowindex].children;
        _.times(columns.length, (index) => {
            if (index === cellIndex) {
                if (columns[index].className.indexOf(SELECTED_CELL_CLASS) === -1)
                    columns[index].className = columns[index].className.concat(SELECTED_CELL_CLASS);
            } else {
                columns[index].className = columns[index].className.replace(SELECTED_CELL_CLASS, "");
            }
        });
    }

    onClickSelectRow(selectedRowIndex, event) {
        if (this.state.inEditMode) {
            return;
        }
        const rows = _.cloneDeep(this.state.rows);

        let target = event.target;
        while (target && target.parentNode && target.getAttribute && !(target.getAttribute('cellindex') || target.getAttribute('tabindex'))) {
            target = target.parentNode;
        }

        let selectedColumnIndex = target && target.getAttribute && (target.getAttribute('cellindex') ? target.getAttribute('cellindex') : target.getAttribute('tabindex'));
        if (selectedColumnIndex !== undefined) {
            selectedColumnIndex = +selectedColumnIndex;

            rows[selectedRowIndex].selected = true;

            this.changeSelectedRow(selectedRowIndex, selectedColumnIndex);

            //must remove editing for other then the selected row
            rows.forEach((row, index) => {
                rows[index].editing = index === (selectedRowIndex) ? selectedColumnIndex : false;
            });
            this.setState({rows});

            this.setState({
                selectedRow: selectedRowIndex,
                selectedCell: selectedColumnIndex,
                rows: rows
            });
        }
    }

    onFilterLinesChanged() {
        this.setState({
            onlyMyLines: !this.state.onlyMyLines,
            selectedRow: undefined,
            selectedCell: undefined,
            inEditMode: false,
            rows: []
        }, () => {
            this.getFilteredLines(this.state.allRows, false);
        });
    }

    hideColumn(columnIndex) {
        let visible = this.state.columns.map((column, index) => {
            let isVisible = column.visible;
            if (index === columnIndex) {
                column.visible = !isVisible;
            }
            return column;
        });
        this.setState({
            columns: visible
        });
    }

    getTotalRows() {
        return this.state.allRows && this.state.allRows.rows ? this.state.allRows.rows.filter((row) => {
            return !row.hidden
        }).length : 0;
    }

    //endregion

    render() {
        const {columns, rows, allColumns, policy} = this.state;

        let gridData = [];
        if (rows && allColumns.length > 0) {
            rows.forEach((row, index) => {
                return gridData.push(row);
            });
        }

        let balanceProps = {
            userData: this.state.userData,
            policy: policy,
            readOnly: this.props.readOnly,
            addRow: this.addRow,
            addingRemovingRow: this.state.addingRemovingRow,
            removeRow: this.showPopup,
            getGridRows: this.refreshGrid,
            selectedRow: this.state.selectedRow,
            rows: this.state.rows,
            translate: this.props.translate,
            onlyMyLines: this.state.onlyMyLines,
            filterLines: this.onFilterLinesChanged,
            columns: columns,
            hideColumn: this.hideColumn
        };

        return (
            <Tab.Container style={{height: "100%"}}>
                {this.state.visible &&
                    <Confirm translate={this.props.translate}
                             message={this.props.translate("myHistory.confirmDelete")}
                             handleAction={this.removeRow} closeCallback={this.close}/>
                }

                <Tab.Content id="myTabContent" className="h-100 w-100" mountOnEnter={false}>
                    <Tab.Pane className="d-block h-100 border-0" id="selected"
                              aria-labelledby="selected-tab"
                              mountOnEnter={false}>
                        <Balance {...balanceProps}/>
                        <div id="accountingGrid" onKeyDown={this.handleKeyDown} tabIndex={-1}
                             ref={(input) => {
                                 this.table = input;
                             }}>
                            <Table.Provider columns={columns.filter(column => column.visible)}
                                            className="table table-hover float-left"
                                            style={{width: 'auto'}}>
                                <Sticky.Header
                                    style={{
                                        width: this.state.gridWidth,
                                        overflow: 'auto'
                                    }}
                                    ref={tableHeader => {
                                        this.tableHeader = tableHeader && tableHeader.getRef();
                                    }}
                                    tableBody={this.tableBody}/>
                                <Sticky.Body rows={gridData} rowKey="rowIndex"
                                             style={{width: this.state.gridWidth, overflow: 'auto'}}
                                             ref={tableBody => {
                                                 this.tableBody = tableBody && tableBody.getRef();
                                             }}
                                             tabIndex={-1}
                                             tableHeader={this.tableHeader}
                                             onRow={this.onRow.bind(this)}
                                />
                            </Table.Provider>
                        </div>

                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        );
    }
}

export default AccountingGrid;

class Balance extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSelector: false
        }
    }

    render() {
        let {userData, policy, readOnly, selectedRow, rows, onlyMyLines, columns} = this.props;

        let columnsList = columns.map((column, index) => {
            return (<li key={index} className="col-md-12" onClick={this.props.hideColumn.bind(this, index)}>
                <ToggleButton bsClass="col-md-12"
                              type="checkbox"
                              onChange={(e) => {
                                  e.preventDefault();
                                  return false
                              }}>{column.header.label}</ToggleButton>
            </li>)
        });

        const btnDisabledClass = this.props.addingRemovingRow ? " disabled" : "";

        return (
            <div className="col-md-12 row px-5 py-3">
                <div className="float-left col-md-6 col-xs-6 px-0">
                    {policy && !readOnly &&
                        <span className={"float-left"}>
                  {policy.allowAdd &&
                      <span className={`vismaicon vismaicon-dynamic vismaicon-add-circle ${btnDisabledClass}`}
                            onClick={this.props.addRow} title={this.props.translate("accoutingGrid.addRow")}/>
                  }
                            {selectedRow !== undefined && rows[selectedRow].canDelete &&
                                <span
                                    className={`vismaicon vismaicon-delete vismaicon-dynamic px-0 ms-3 ${btnDisabledClass}`}
                                    title={this.props.translate("accoutingGrid.deleteRow")}
                                    onClick={this.props.removeRow}/>
                            }
             </span>
                    }
                    <span className="vismaicon vismaicon-refresh vismaicon-dynamic float-left mx-3"
                          onClick={this.props.getGridRows}
                          title={this.props.translate("accoutingGrid.reload")}/>
                    <Form.Check type="checkbox" className="checkbox float-left mt-1"
                                id={"OnlyMyLines"}
                                checked={onlyMyLines}
                                onChange={this.props.filterLines}
                                label={this.props.translate("accoutingGrid.onlyMyLines")}/>
                </div>

                {userData && userData.balance &&
                    <span className="col-md-6 px-0">
                {userData.balance.name} <span className={userData.balance.value < 0 ? "text-danger" : ''}>
                    <strong>  {userData.balance.value} </strong>
                </span>
                        {false &&
                            <div>
                <span className="glyphicon glyphicon-cog pull-right" onClick={() => {
                    this.setState({
                        showSelector: !this.state.showSelector
                    })
                }}/>
                                {this.state.showSelector &&
                                    <ul className="list-unstyled col-md-3 pull-right combo-list px-0"
                                    >
                                        {columnsList}
                                    </ul>
                                }
                            </div>
                        }
                        </span>
                }
            </div>);
    }
}
