import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as reduxSelectors from "components/store/application.reducers";
import * as logger from "utils/logger.function";
import {isLastInTheIteration, navigateBetweenTasks} from "components/mytasks/action/navigateBetweenTasks.action";
import {navigateToMyTasks} from "components/router/router.function.js";
import {removeTaskFromStore} from "components/mytasks/myTasks.action";


const HandleTask = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tasks = useSelector((store) => reduxSelectors.getTasksForRender(store));
    let noErrorPressent = !useSelector((store) => reduxSelectors.getErrorMessageVisibility(store));

    let userSettings = useSelector((store) => reduxSelectors.getUsersSettings(store));
    let loopTasks = userSettings.nextTask;

    logger.debug("task handled", props.taskKey);
    // if we're outside the iteration, return back to taskList
    const oneHandleOnly = useSelector((store) => reduxSelectors.getOneHandleOnlyFlag(store));
    const tryLoopTasksFlag = useSelector((store) => reduxSelectors.getTryLoopTasksFlag(store));
    const notInMyTasks = window.location.pathname !== "/";

    if (close)
        close();

    //user opened a subset of tasks
    if (!oneHandleOnly) {
        if (isLastInTheIteration(props.taskKey, tasks.selectedKeys)) {
            if (noErrorPressent && notInMyTasks)
                navigate(navigateToMyTasks());
            return;
        }
        logger.debug("open sublist of tasks and loop within that list");
        if (noErrorPressent) {
            navigate(navigateBetweenTasks(props.taskKey, true));
            dispatch(removeTaskFromStore(props.taskKey));
        }
        return;
    }


    if (!loopTasks || !tryLoopTasksFlag || isLastInTheIteration(props.taskKey, tasks.filteredKeys)) {
        logger.debug("TaskHandlingActive=false and oneHandleOnly=true so navigate to my tasks");
        if (noErrorPressent) {
            dispatch(removeTaskFromStore(props.taskKey));
            if (notInMyTasks)
                navigate(navigateToMyTasks());
        }
        return;
    }

    logger.debug("in iteration and not last, navigating");
    // otherwise try to navigate to the next
    navigate(navigateBetweenTasks(props.taskKey, true));
    // remove from the store, so disable navigating back to handled task
    // we have to dispatch removing from the store after the navigation, because otherwise taskDetail starts rerendering (it's connected to the store)
    dispatch(removeTaskFromStore(props.taskKey));



}

export default HandleTask;