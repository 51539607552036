import React from "react";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements, RouterProvider,
} from "react-router-dom";

import {withOidcSecure} from "@axa-fr/react-oidc";

import {NotFound, WithoutStartup} from "../errorpage/ErrorPage.component.jsx";
import RouteTracker from "./VirtualPageTracker.component";
import ConnectLogout from "OidcComponents/ConnectLogout.function";
import MyTasks from '../mytasks/MyTasks.component';
import MyHistory from "../myhistory/MyHistory.component";
import TaskDetailMasterLayer from "../taskdetails-remake/TaskDetailsMasterLayer.component.jsx";
import HistoryTaskDetail from "../historydetail/HistoryTaskDetail.component";
import ProcessDetailsMasterLayer from "../processdetail/TaskDetailsMasterLayer.component.jsx";
import TaskOverview from '../taskoverview/TaskOverview.component.jsx';
import DocumentOverview from '../documentoverview/DocumentOverview.component.jsx';
import DocumentTaskDetail from "../documentdetail/DocumentTaskDetail.component";
import Settings from "../settings/Settings.component.jsx";
import ApproverNotifications from "../settings/ApproverNotifications.component";
import SubstituteSettings from "../settings/SubstituteSettings.component";
import OtherSettings from "../settings/OtherSettings.component.jsx";
import ConfigurationWrapper from "../configuration/ConfigurationWrapper.component.jsx";
import Configuration from "../configuration/Configuration.component";
import Workflows from "../workflows/Workflows.component";
import DueSettings from '../dueSettings/DueSettings.component';
import Substitutes from "../substitutes/Substitutes.component";
import Managers from "../managers/Managers.component";
import WorkflowDetails from "../workflowdetails/WorkflowDetails.component";
import Departments from "../departments/Departments.component";
import CostUnits from "../costunits/CostUnits.component";
import Roles from "../roles/Roles.component";
import Projects from '../projects/Projects.component';
import OrganisationChart from "../organisationChart/OrganisationChart.component";
import PreApproval from "../preapproval/PreApproval.component";
import WorkflowDetailsHistory from "../workflowdetails/WorkflowDetailsHistory.component";
import DecisionTables from "../decisionTables/decisionTables.component";
import DecisionTableDetails from "../decisionTableDetails/decisionTableDetails.component";
import {withRouteBlocker} from "components/router/withRouter";
import ProcessOverview from "components/processoverview/ProcessOverview.component.jsx";

import {AppLogin} from "../../app";


export default function ApprovalRouter() {

    const TaskDetailsWithRouteBlocker = withOidcSecure(withRouteBlocker(TaskDetailMasterLayer));
    const ProcessDetailsWithRouteBlocker = withOidcSecure(withRouteBlocker(ProcessDetailsMasterLayer));
    const HistoryDetailsWithRouteBlocker = withOidcSecure(withRouteBlocker(HistoryTaskDetail));
    const DocumentDetailsWithRouteBlocker = withOidcSecure(withRouteBlocker(DocumentTaskDetail));
    const ApproverNotificationsWithRouteBlocker = withOidcSecure(withRouteBlocker(ApproverNotifications));
    const SubstituteSettingsWithRouteBlocker = withOidcSecure(withRouteBlocker(SubstituteSettings));
    const OtherSettingsWithRouteBlocker = withOidcSecure(withRouteBlocker(OtherSettings));

    const DueSettingsWithRouteBlocker = withRouteBlocker(DueSettings);
    const CostUnitsWithRouteBlocker = withRouteBlocker(CostUnits);
    const WorkflowDetailsWithRouteBlocker = withRouteBlocker(WorkflowDetails);

    const SecureMyTasks = withOidcSecure(MyTasks);
    const SecureHistory = withOidcSecure(MyHistory);
    const SecurePreApproval = withOidcSecure(PreApproval);
    const SecureTaskOverview = withOidcSecure(TaskOverview);
    const SecureProcessOverview = withOidcSecure(ProcessOverview);
    const SecureDocumentOverview = withOidcSecure(DocumentOverview);
    const SecureSettings = withOidcSecure(Settings);


    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RouteTracker/>}>
                <Route exact path="/" element={<SecureMyTasks/>}/>


                <Route exact path="/task/:taskId" element={<TaskDetailsWithRouteBlocker/>}/>
                <Route path="preapproval" element={<SecurePreApproval/>}/>
                <Route path="history" element={<SecureHistory/>}/>
                <Route path="taskhistory/:taskId" element={<HistoryDetailsWithRouteBlocker/>}/>
                <Route path="processoverview" element={<SecureProcessOverview/>}/>
                <Route path="processtask/:taskId" element={<ProcessDetailsWithRouteBlocker/>}/>
                <Route path="taskoverview" element={<SecureTaskOverview/>}/>
                <Route path="documentoverview" element={<SecureDocumentOverview/>}/>
                <Route path="document/:taskId" element={<DocumentDetailsWithRouteBlocker/>}/>


                <Route path="login" element={<AppLogin/>}/>

                <Route path="settings" element={<SecureSettings/>}>
                    <Route index element={<ApproverNotificationsWithRouteBlocker/>}/>
                    <Route path="approverNotifications" element={<ApproverNotificationsWithRouteBlocker/>}/>
                    <Route path="substitute" element={<SubstituteSettingsWithRouteBlocker/>}/>
                    <Route path="otherSettings" element={<OtherSettingsWithRouteBlocker/>}/>
                </Route>

                <Route path="configuration">
                    <Route index element={<Substitutes/>}/>
                    <Route path="substitutes" element={<Substitutes/>}/>
                    <Route path="oldsettings" element={<Configuration/>}/>
                    <Route path="workflows" element={<Workflows/>}/>
                    <Route path="workflows/workflow/:workflowId" element={<WorkflowDetailsWithRouteBlocker/>}/>
                    <Route path="workflows/workflowHistory/:workflowId/:version" element={<WorkflowDetailsHistory/>}/>
                    <Route path="dueSettings" element={<DueSettingsWithRouteBlocker/>}/>
                    <Route path="roles" element={<Roles/>}/>
                    <Route path="managers" element={<Managers/>}/>
                    <Route path="departments" element={<Departments/>}/>
                    <Route path="projects" element={<Projects/>}/>
                    <Route path="costUnits" element={<CostUnitsWithRouteBlocker/>}/>
                    <Route path="organisationChart" element={<OrganisationChart/>}/>
                    <Route path="decisionTables" element={<DecisionTables/>}/>
                    <Route path="decisionTables/details/:name/:id" element={<DecisionTableDetails/>}/>
                </Route>


                <Route path="/error" element={<WithoutStartup/>}/>
                <Route path="*" element={<NotFound/>}/>
                <Route path="logout" element={<ConnectLogout/>}/>
            </Route>
        )
    );

    return <RouterProvider router={router}/>
}