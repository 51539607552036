// import the styles for whole app
import './scss/bootstrap.scss';
import './scss/nc4.scss';
import './scss/utilities.scss';

// datepicker
import "flatpickr/dist/themes/light.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-loading-skeleton/dist/skeleton.css'; //skeleton loading

import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {ErrorBoundary, Provider as RollbarProvider} from '@rollbar/react'

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './utils/snowplow';

import Router from 'components/router/Router.component.jsx';
import store from 'components/store/Store.component.jsx';
import {createRoot} from 'react-dom/client';
import {OidcProvider, TokenRenewMode} from "@axa-fr/react-oidc";

import SessionLost from "OidcComponents/SessionLost.function.tsx";
import Loading from "OidcComponents/Loading.function";
import {navigateToLoginPage} from "OidcComponents/navigateToLoginPage.function";


const rollbarConfig = {
    accessToken: window.ENVIRONMENT.ROLLBAR_ACCESS_TOKEN,
    environment: window.ENVIRONMENT.NAME,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        client: {
            javascript: {
                code_version: window.ENVIRONMENT.VERSION
            }
        }
    }
};

const oidcConfig = {
    client_id: "vnetapproval-spa",
    authority: "https://connect.identity.stagaws.visma.com",
    redirect_uri: window.location.origin+ '/#/authentication/callback',
   // silent_login_uri:"https://connect.identity.stagaws.visma.com/authorize",
    silent_redirect_uri: window.location.origin + '/#/authentication/silent-callback',
    scope: "openid email profile vismanetusersession:read vismanetuserapplications:read roles.vismanet approval:interactive",
    service_worker_relative_url: "/OidcServiceWorker.js",
    service_worker_only: false,
    //extras: {grant_type: "authorization_code"},
    response_type: "code id_token token offline_access",
    refresh_time_before_tokens_expiration_in_second: 40,
    token_renew_mode: TokenRenewMode.access_token_invalid,
};

const App = () => {
    return (
        <ErrorBoundary>
        <OidcProvider configuration={oidcConfig} sessionLostComponent={SessionLost}
                      loadingComponent={Loading}
                      authenticatingComponent={Loading}
                      callbackSuccessComponent={Loading}
                      onLogoutFromAnotherTab={navigateToLoginPage}
                      authenticatingErrorComponent={SessionLost}>
            <RollbarProvider config={rollbarConfig}>
                    <ReduxProvider store={store}>
                        <Router/>
                        <ToastContainer
                            position={'top-right'}
                            autoClose={2500}
                            hideProgressBar={true}
                            draggable
                            closeButton={false}/>
                    </ReduxProvider>
            </RollbarProvider>
        </OidcProvider>
        </ErrorBoundary>
    );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);

