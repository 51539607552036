import {useOidc, useOidcIdToken, useOidcUser} from '@axa-fr/react-oidc';

import {OidcUserInfo} from "@axa-fr/oidc-client";
import {useEffect} from "react";
import Loading from "OidcComponents/Loading.function";


interface OidcUserRoleInfo extends OidcUserInfo {
    role?: string[];
}

export const AppLogin = () => {
    const {login, isAuthenticated} = useOidc();
    const {idToken, idTokenPayload} = useOidcIdToken();
    // @ts-ignore
    const {oidcUser} = useOidcUser<OidcUserRoleInfo>();


    useEffect(() => {
        if (!isAuthenticated)
            return login();
    }, [isAuthenticated]);

    return (
        <div className="container-fluid mt-3 display-none">
            <div className="card">
                <div className="card-body">

                    {!isAuthenticated &&
                        <Loading/>
                    }

                    {isAuthenticated || false &&
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">ID Token</h5>
                                {<p className="card-text">IdToken: {JSON.stringify(idToken)}</p>}
                                {idTokenPayload != null &&
                                    <p className="card-text">IdToken Payload: {JSON.stringify(idTokenPayload)}</p>}
                            </div>

                            <h5 className="card-title">User information</h5>
                            <p className="card-text">{JSON.stringify(oidcUser)}</p>
                        </div>}

                </div>
            </div>
        </div>
    );
};


export default AppLogin;