import {ComponentType, useEffect} from "react";
import {useOidc} from "@axa-fr/react-oidc";
import {navigateToLoginPage} from "OidcComponents/navigateToLoginPage.function";

const SessionLost: ComponentType<any> = () => {
    const {isAuthenticated} = useOidc();


    useEffect(() => {
        if (!isAuthenticated)
            navigateToLoginPage();
    }, [isAuthenticated]);


    return null;
};


export default SessionLost;