import  {ComponentType} from "react";


const Loading: ComponentType<any> = () => {
    // const {login, isAuthenticated} = useOidc();
    //
    //
    // useEffect(() => {
    //     if (!isAuthenticated)
    //         return login();
    // }, [isAuthenticated]);


    return (
        <div className="container-fluid mt-3 w-100 h-100 d-flex vertical-center align-items-center">
            <span className="spinner spinner-default-blue loading"/>
        </div>
    );
};


export default Loading;